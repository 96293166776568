<template>
  <div class="page-content guarantee">
    <div class="title">被担保人信息</div>

    <div class="page-block">
      <div v-for="(item, i) in guaranteeInfo" :key="i">
        <van-cell
          :title="item.name"
          :is-link="item.name == '个人信息' && item.color == 'b'"
          @click="item.click ? item.click() : void 0"
        >
          <template #default>
            <span
              :class="
                item.color == 'g' ? 'suc' : item.color == 'b' ? 'usual' : ''
              "
              >{{ item.text }}</span
            >
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
import { stringHypose } from "@/utils/tool";
import { guaChecked, guaInfo } from "@/api/apply";
export default {
  data() {
    return {
      detail: {},
      guaChecked: false,
      signKey: ""
    };
  },
  created() {
    let { signKey } = this.$route.query;
    if (signKey.includes("+")) signKey = encodeURIComponent(signKey);
    this.signKey = signKey || "";
  },
  computed: {
    guaranteeInfo() {
      const {
        authStatus,
        certNo,
        customerMobile,
        customerName,
        customerPhone,
        idCardNo,
        completeFlag
      } = this.detail;
      let phone = customerPhone || customerMobile;
      let no = certNo || idCardNo;
      return [
        {
          name: "申请人",
          text: customerName || "-"
        },
        {
          name: "手机号",
          text: phone ? stringHypose(phone) : "-"
        },
        {
          name: "身份证号",
          text: no ? stringHypose(no, [6, 14]) : "-"
        },
        {
          name: "身份认证",
          text: authStatus == "Y" ? "已完成" : "未完成",
          color: authStatus == "Y" ? "g" : "b"
        },
        {
          name: "个人信息",
          text: completeFlag == "Y" ? "已完成" : "未完成",
          color: completeFlag == "Y" ? "g" : "b",
          click: () => {
            this.$router.push(`/guarantee-person?signKey=${this.signKey}`);
          }
        }
      ];
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.getGuaChecked();
      this.getGuaranteeInfo();
    },
    // async getGuaChecked() {
    //   try {
    //     let { signKey } = this.$route.query;
    //     if (!signKey) throw "路径秘钥错误!";
    //     signKey = encodeURIComponent(signKey);
    //     const { data } = await guaChecked(signKey);
    //     this.guaChecked = data || false;
    //   } catch (error) {
    //     this.$errMsg(error);
    //   }
    // },
    async getGuaranteeInfo() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        if (!this.signKey) throw "路径秘钥错误!";
        const { data } = await guaInfo(this.signKey);
        this.detail = data || {};
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../detail/input-style.scss";
.guarantee {
  background: linear-gradient(
    to bottom,
    #3fc2ff 0%,
    #60b1ff 25%,
    #acd8ff 40%,
    #ebf6ff 100%
  );
  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 80px;
    padding: 0 32px;
    color: #fff;
  }
  .page-block {
    overflow: hidden;
    margin: 40px 32px;
    margin-top: 0;
    border-radius: 8px;
    position: relative;
    padding-top: 40px;
    box-shadow: 0 0 10px #aaa6;
    background: #fff;
    .van-cell {
      .suc {
        color: #52c41a;
      }
      .warn {
        color: #faad14;
      }
      .usual {
        color: #1890ff;
      }
    }
  }
}
</style>
